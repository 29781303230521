<content-loading-animation *ngIf="loading"></content-loading-animation>
<app-form-wrapper [formGroup]="form" *ngIf="!loading" [titleMsg]="'BROADCASTER' | translate" [isEdit]="isEdit" [hasTourStartButton]="false">
    <section id="info">
        <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
        <!-- Name -->
        <app-input-generic-field
            [formControl]="form.controls.name"
            [textFieldName]="'NAME' | translate"
            [isParentFormSubmitted]="submitted"
            [checkDuplicateNames]="broadcasterNames"
            type="text"
        ></app-input-generic-field>

        <!-- LOCATION -->
        <div class="form-group">
            <label for="location_text" translate>
                LOCATION
                <fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                <ng-template #LocationContent>{{ "TOOLTIP.LOCATION" | translate }}</ng-template></label
            >
            <zx-location-search [model]="form.controls.location.value" (modelChange)="form.controls.location.setValue($event)"></zx-location-search>
        </div>
    </section>
    <section id="config">
        <app-form-section-title [title]="'CONFIGURATION' | translate"></app-form-section-title>
        <fieldset *ngIf="!cluster._frontData.is_auto_scaling">
            <div class="row">
                <div class="col-12 col-sm-6 mb-3">
                    <app-form-field-warper
                        labelFor="apiUser"
                        [isRestartTooltip]="isEdit"
                        [isRequired]="true"
                        [isInvalid]="submitted && form.controls.api_user.errors"
                        [labelText]="'API_USER' | translate"
                    >
                        <div class="input-group">
                            <input
                                placeholder="{{ 'API_USER' | translate }}"
                                class="form-control"
                                type="text"
                                [ngClass]="{ 'is-invalid': form.controls.api_user.invalid, disabled: form.controls.api_user.disabled }"
                                [formControl]="form.controls.api_user"
                            />
                            <app-copy-button [stringToCopy]="form.controls.api_user.value"></app-copy-button>
                            <app-input-errors-indicator
                                fieldName="{{ 'API_USER' | translate }}"
                                [errors]="form.controls.api_user.errors"
                                [invalid]="submitted && form.controls.api_user.errors"
                            ></app-input-errors-indicator>
                        </div>
                    </app-form-field-warper>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                    <app-password-input
                        [passwordInput]="form.controls.api_password.value"
                        (passwordInputChange)="form.controls.api_password.setValue($event)"
                        [isFormSubmitted]="submitted"
                        [isEdit]="isEdit"
                    ></app-password-input>
                </div>
            </div>
        </fieldset>

        <!-- SSh keys -->
        <app-select-generic-field
            *ngIf="!cluster._frontData.is_auto_scaling"
            title="{{ 'SSH_KEY' | translate }}"
            valueToBind="id"
            [formControl]="form.controls.remote_access_key_id"
            circleContent="{{ 'TOOLTIP.SSH_KEY' | translate }} > {{ 'SSH_KEYS' | translate }}"
            placeHolder="{{ 'SELECT' | translate }} {{ 'SSH_KEY' | translate }}"
            [isRestartRequiredTip]="isEdit"
            [isParentFormSubmitted]="submitted"
            [items]="accessKeys"
        >
        </app-select-generic-field>

        <!-- Type -->
        <app-radio-input
            label="{{ 'TYPE' | translate }}"
            tooltip="{{ 'TOOLTIP.BROADCASTER_TYPE_PRIMARY' | translate }} <br> {{ 'TOOLTIP.BROADCASTER_TYPE_BACKUP' | translate }}"
            [circleTip]="true"
            [inputKeyAndValues]="bxTypes"
            [formControl]="form.controls.is_backup"
            [isParentFormSubmitted]="submitted"
        >
        </app-radio-input>
        <small class="mb-1" translate>
            BACKUP_BROADCASTERS_WILL_ONLY_RECEIVE_SOURCES_IF_MANUALLY_CONFIGURED_OR_IF_ALL_PRIMARY_BROADCASTERS_ARE_UNAVAILABLE
        </small>
    </section>
    <br />
    <app-collapsed-form-section title="{{ 'ADVANCED' | translate }}">
        <!-- Streaming IP -->
        <app-form-field-warper
            labelFor="apiUser"
            [isRestartTooltip]="isEdit"
            [isRequired]="true"
            [isInvalid]="submitted && form.controls.streaming_ip.errors"
            [labelText]="'PUBLIC_IP' | translate"
        >
            <div class="form-group">
                <div class="input-group align-items-center">
                    <div class="input-group-prepend">
                        <div class="input-group">
                            <div class="form-check form-check-inline">
                                <input type="checkbox" class="form-check-input" id="is_auto_detect" name="is_auto_detect" [formControl]="isAutoDetectControl" />
                                <label class="form-check-label" for="is_auto_detect" translate>AUTO_DETECT</label>
                            </div>
                        </div>
                    </div>
                    <div class="input-group-div">
                        <input
                            type="text"
                            id="streaming_ip"
                            name="streaming_ip"
                            placeholder="{{ 'IP' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && form.controls.streaming_ip.errors }"
                            [formControl]="form.controls.streaming_ip"
                        />
                        <app-input-errors-indicator
                            patternErrorMessage="{{ 'INVALID_IPV4_ADDRESS' | translate }}."
                            fieldName="{{ 'IP' | translate }}"
                            [errors]="form.controls.streaming_ip.errors"
                            [invalid]="submitted && form.controls.streaming_ip.errors"
                        ></app-input-errors-indicator>
                    </div>
                </div>
            </div>
        </app-form-field-warper>
        <!-- Private IP -->
        <app-form-field-warper
            labelFor="apiUser"
            [isRestartTooltip]="isEdit"
            [isRequired]="true"
            [isInvalid]="submitted && form.controls.private_ip.errors"
            [labelText]="'PRIVATE_IP' | translate"
        >
            <div class="form-group">
                <div class="input-group align-items-center">
                    <div class="input-group-prepend">
                        <div class="input-group">
                            <div class="form-check form-check-inline">
                                <input type="checkbox" class="form-check-input" id="no_private_ip" name="no_private_ip" [formControl]="noPrivateIpControl" />
                                <label
                                    class="form-check-label"
                                    for="no_private_ip"
                                    [translate]="manualBroadcaster ? 'BX_MANUAL_PRIVATE_IP_TEXT' : 'BX_PRIVATE_IP_TEXT'"
                                ></label>
                            </div>
                        </div>
                    </div>
                    <div class="input-group-div">
                        <input
                            type="text"
                            id="private_ip"
                            name="private_ip"
                            placeholder="{{ 'IP' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && form.controls.private_ip.errors }"
                            [formControl]="form.controls.private_ip"
                        />
                        <app-input-errors-indicator
                            patternErrorMessage="{{ 'INVALID_IPV4_ADDRESS' | translate }}."
                            fieldName="{{ 'IP' | translate }}"
                            [errors]="form.controls.private_ip.errors"
                            [invalid]="submitted && form.controls.private_ip.errors"
                        ></app-input-errors-indicator>
                    </div>
                </div>
            </div>
        </app-form-field-warper>

        <fieldset class="form-group bordered">
            <legend>{{ "BANDWIDTH_LIMITS" | translate }} [Mbps]</legend>
            <div class="row">
                <div class="col">
                    <label for="input_bw_limit" [ngClass]="{ 'is-invalid': submitted && form.controls.input_bw_limit.errors }" translate> INPUT </label>
                    <input
                        type="number"
                        id="input_bw_limit"
                        name="input_bw_limit"
                        placeholder="{{ 'INPUT' | translate }}"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.controls.input_bw_limit.errors }"
                        [formControl]="form.controls.input_bw_limit"
                    />
                    <app-input-errors-indicator
                        fieldName="{{ 'INPUT_LIMIT' | translate }}"
                        [errors]="form.controls.input_bw_limit.errors"
                        [invalid]="submitted && form.controls.input_bw_limit.errors"
                    ></app-input-errors-indicator>
                </div>
                <div class="col">
                    <label for="output_bw_limit" [ngClass]="{ 'is-invalid': submitted && form.controls.input_bw_limit.errors }" translate>OUTPUT</label>
                    <input
                        type="number"
                        id="output_bw_limit"
                        name="output_bw_limit"
                        placeholder="{{ 'OUTPUT' | translate }}"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.controls.output_bw_limit.errors }"
                        [formControl]="form.controls.output_bw_limit"
                    />
                    <app-input-errors-indicator
                        fieldName="{{ 'INPUT_LIMIT' | translate }}"
                        [errors]="form.controls.output_bw_limit.errors"
                        [invalid]="submitted && form.controls.input_bw_limit.errors"
                    ></app-input-errors-indicator>
                </div>
            </div>
        </fieldset>
    </app-collapsed-form-section>

    <div *ngIf="!isEdit && !hasRemainingIps && this.cluster?.aws_account_id">
        <fa-icon icon="exclamation-circle" class="me-1" [ngClass]="{ 'status-warning': !cluster.require_eip, 'status-error': cluster.require_eip }"></fa-icon>
        <small translate>TOOLTIP.NO_MORE_ELASTIC_IP</small>
    </div>

    <app-error [marginBottom]="true" [marginTop]="true"></app-error>

    <app-zx-form-bottom-buttons
        (submit)="onSubmit()"
        [isFormValid]="form.valid"
        [(submitted)]="submitted"
        [isSaving]="saving"
        [isEdit]="isEdit"
        [cancelUrlNavigate]="cancelUrl"
    >
    </app-zx-form-bottom-buttons>
</app-form-wrapper>
