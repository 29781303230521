import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { ClusterFormComponent } from "./cluster-form/cluster-form.component";
import { ClusterListComponent } from "./cluster-list/cluster-list.component";
import { ClusterComponent } from "./cluster/cluster.component";
import { ClusterListResolverService } from "./cluster-list/cluster-list-resolver.service";
import {
    ClusterBreadcrumbsResolverService,
    ClusterFormBreadcrumbsResolverService,
    ClusterNewBXBreadcrumbsResolverService
} from "./cluster/cluster-resolver.service";
import {
    BroadcasterBreadcrumbsResolverService,
    BroadcasterFormBreadcrumbsResolverService
} from "./broadcaster/broadcaster-resolver.service";
import { BroadcasterFormComponent } from "../../components/broadcasters/broadcaster-form/broadcaster-form.component";
import { BroadcasterComponent } from "./broadcaster/broadcaster.component";
import { PendingChangesGuard } from "src/app/guards/pending-changes.guard";

const clusterRoutes: Routes = [
    {
        path: Constants.urls.clusters + "/new",
        component: ClusterFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_CLUSTER",
                    link: "https://zixidocumentation.atlassian.net/l/c/J14tiSrZ"
                }
            ],
            breadcrumbs: [
                {
                    label: "Broadcaster Clusters",
                    url: `/${Constants.urls.clusters}`
                },
                {
                    label: "New Broadcaster Cluster"
                }
            ]
        }
    },
    {
        path: Constants.urls.clusters + "/:clusterId/" + Constants.urls.broadcasters + "/new",
        component: BroadcasterFormComponent,
        canActivate: [AuthGuard],
        resolve: { breadcrumbs: ClusterNewBXBreadcrumbsResolverService },
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_BROADCASTER",
                    link: "https://zixidocumentation.atlassian.net/l/c/up1PmpbX"
                }
            ]
        }
    },
    {
        path: Constants.urls.clusters + "/:clusterId/:name/:action",
        component: ClusterFormComponent,
        canActivate: [AuthGuard],
        resolve: { breadcrumbs: ClusterFormBreadcrumbsResolverService },
        data: {
            help: [
                {
                    menu: "HELP_MENU.BROADCASTER_CLUSTER_CONFIG",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/PQBYRQ"
                }
            ]
        }
    },
    {
        path:
            Constants.urls.clusters +
            "/:clusterId/" +
            Constants.urls.broadcasters +
            "/:broadcasterId/:broadcasterName/:action",
        component: BroadcasterFormComponent,
        resolve: { breadcrumbs: BroadcasterFormBreadcrumbsResolverService },
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.clusters,
        component: ClusterListComponent,
        canActivate: [AuthGuard],
        resolve: {
            cluster: ClusterListResolverService
        },
        children: [
            {
                path: ":clusterId/:name",
                component: ClusterComponent,
                resolve: { breadcrumbs: ClusterBreadcrumbsResolverService }
            },
            {
                path: ":clusterId/" + Constants.urls.broadcasters + "/:broadcasterId/:broadcasterName",
                component: BroadcasterComponent,
                resolve: { breadcrumbs: BroadcasterBreadcrumbsResolverService }
            }
        ],
        data: {
            breadcrumbs: [
                {
                    label: "Broadcaster Clusters",
                    url: `/${Constants.urls.clusters}`
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.CLUSTERS",
                    link: "https://zixidocumentation.atlassian.net/l/c/TsVHgAg1"
                },
                {
                    menu: "HELP_MENU.NEW_CLUSTER",
                    link: "https://zixidocumentation.atlassian.net/l/c/J14tiSrZ"
                },
                {
                    menu: "HELP_MENU.BROADCASTER",
                    link: "https://zixidocumentation.atlassian.net/l/c/87oZeNgy"
                },
                {
                    menu: "HELP_MENU.NEW_BROADCASTER",
                    link: "https://zixidocumentation.atlassian.net/l/c/up1PmpbX"
                },
                {
                    menu: "HELP_MENU.INSIGHTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/NQDVGAE"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(clusterRoutes)],
    exports: [RouterModule]
})
export class ClustersRouting {}
